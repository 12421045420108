const delay = (t: number) => new Promise(r => setTimeout(r, t))

const getTimeOfDelay = (numOfTry: number) => {
  if (numOfTry < 20) {
    return 30 // 600
  } else if (numOfTry < 60) {
    return 50 // 2600
  } else if (numOfTry < 100) {
    return 300 // 14600
  }

  return 1000
}

async function setupMFEApps(numOfTry = 0) {
  if (
    !globalThis['mpManifest'] ||
    !globalThis['ordersWidgetUrl'] ||
    !globalThis['ordersWidgetOnMyOrdersUrl']
  ) {
    await delay(getTimeOfDelay(numOfTry))
    return setupMFEApps(numOfTry + 1)
  }
}

export { setupMFEApps }
